<template>
  <defaultTemplate v-loading="loading">
    <div class="min-height-screen-120 text-white pd-5">
      <h3 class="text-center text-white">
        EXAC เติมเต็มความเป็นเลิศส่งออกไทย <br />เติบโตไกลอย่างยั่งยืน
      </h3>
      <h1 class="text-center">กำลังออกจากระบบ...</h1>
    </div>
  </defaultTemplate>
</template>
<script>
import defaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
export default {
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    this.signout();
  },
  methods: {
    signout() {
      this.fullLoading = true;
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      // this.$store.commit("SIGNOUT");
      window.localStorage.removeItem("eram");
      delete HTTP.defaults.headers.common.Authorization;
      //this.$router.push("/");
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
    }
  },
  components: {
    defaultTemplate
  }
};
</script>
